import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import { Link } from 'gatsby'

import Container from '@material-ui/core/Container'

const NotFoundPage = () => {
  return (
    <Layout>
      <Helmet titleTemplate="%s">
        <title>404 NOT FOUND|アイデアマップ</title>
        <meta
          name="description"
          content="インターネット最後のページを活用するには。"
        />
      </Helmet>

      <section className="ContentDetail">
      <Container>
      <div className="ContentDetail-header">
        <div className="ContentDetail-header-inner">
            <p className="ContentDetail-header-category">
                <span>404 NOT FOUND</span>
            </p>
        </div>
        <h1 className="TextCenter">お探しのページは見つかりませんでした</h1>
      </div>
      <div className="ContentDetail-image">
        <img src="/img/person-standing-near-lake-670720.jpg" />
      </div>
      <div className="ContentDetail-main">
        <div className="ContentDetail-content">
          <div className="ContentDetail-content-topics">
            <h3>
              <span>TOPICS</span>
              ページの移動や削除で「ページを見られないユーザー」を対象に、表示するページである。
            </h3>
          </div>
          <div className="ContentDetail-content-idea">
            <h3><span className="Icon-idea">IDEA</span>
              対象を「行き場所がなくなったユーザー」とし、同じ目線から親しく話しかけるメッセージを用意し、共感を誘うページとする。
            </h3>
          </div>
        </div>

        <div className="ContentDetailPostContent-markdown">
          <h2>お探しのアイデアはここにはありません。</h2>
          <p>
            アイデアとは既存の要素の新しい組み合わせから生まれます。<br />
            組み合わせるべき要素はこのページにはありません。トップページに戻りましょう。<br />
            <br />
            もし、404ページのアイデアをお探しの場合は、ここがエラーページです。おめでとう！
          </p>
        </div>
      </div>

      <div>
        <ul className="PageNaviList">
          <li className="PageNaviList-item PageNaviList-prev">
            <Link to="/">
              <span>
                トップページ
              </span>
            </Link>
          </li>
        </ul>
      </div>
      </Container>
      </section>
    </Layout>
  )
}

export default NotFoundPage
